/***************/
/**   Fonts   **/
/***************/
@font-face {
  font-family: "RalewayBlack";
  src: local("Raleway-Black"),
    url(./fonts/Raleway/Raleway-Black.ttf) format("truetype");
}

@font-face {
  font-family: "RobotoBlack";
  src: local("Roboto-Black"),
    url(./fonts/Roboto/Roboto-Black.ttf) format("truetype");
}

@font-face {
  font-family: "RobotoMedium";
  src: local("Roboto-Medium"),
    url(./fonts/Roboto/Roboto-Medium.ttf) format("truetype");
}

@font-face {
  font-family: "RobotoLight";
  src: local("Roboto-Light"),
    url(./fonts/Roboto/Roboto-Light.ttf) format("truetype");
}

*,
*::after,
*::before {
  box-sizing: border-box;
}

:root {
  font-size: 1em;
}

body {
  margin: 0;
  --color-text: #01949e;
  --color-bg: #fff;
  --color-link: #025b6d;
  /*also used for small line above desc */
  --color-link-hover: #000;
  --color-deco: #025b6d;
  /* figure*/
  color: var(--color-text);
  background-color: var(--color-bg);
  font-family: paralucent, sans-serif;
  font-family: quiroh, sans-serif;
  font-family: mr-eaves-xl-sans, sans-serif;
}

/* Page Loader */
.js .loading::before {
  content: "";
  position: fixed;
  z-index: 100000;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: var(--color-bg);
}

.js .loading::after {
  content: "";
  position: fixed;
  z-index: 100000;
  top: 50%;
  left: 50%;
  width: 60px;
  height: 60px;
  margin: -30px 0 0 -30px;
  pointer-events: none;
  border-radius: 50%;
  opacity: 0.4;
  background: var(--color-link);
  animation: loaderAnim 0.7s linear infinite alternate forwards;
}

a {
  text-decoration: none;
  color: var(--color-link);
  outline: none;
}

a:hover,
a:focus {
  color: var(--color-link-hover);
  outline: none;
}

[data-scroll] {
  will-change: transform;
}

.content {
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: center;
  padding: 0 30px 0 30px;
  counter-reset: figure;
}

.item {
  margin: 10vh auto;
  max-width: 100%;
  position: relative;
  will-change: transform;
}

.item::before {
  counter-increment: figure;
  content: counter(figure, decimal-leading-zero);
  position: absolute;
  font-family: paralucent, sans-serif;
  font-size: 10rem;
  color: var(--color-deco);
  bottom: calc(100% - 3rem);
  background: url(./img/textBg.jpg) no-repeat;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.item:nth-child(odd)::before {
  left: 40px;
}

.item:nth-child(even)::before {
  right: 40px;
}

.item__img-wrap {
  --aspect-ratio: 1/1.5;
  overflow: hidden;
  width: 500px;
  margin: 0 auto;
  padding-bottom: calc(100% / (var(--aspect-ratio)));
  max-width: calc(100% - 2rem);
  will-change: transform;
}

/*
.item:first-child .item__img-wrap {
  --aspect-ratio: 8/10;
  --image: url(https://tympanus.net/Tutorials/SmoothScrollAnimations/img/1.jpg);
}
*/

/*
.item:nth-child(3) .item__img-wrap {
  --aspect-ratio: 60/75;
  --image: url(https://tympanus.net/Tutorials/SmoothScrollAnimations/img/3.jpg);
}

.item:nth-child(4) .item__img-wrap {
  width: 800px;
  --aspect-ratio: 900/505;
  --image: url(https://tympanus.net/Tutorials/SmoothScrollAnimations/img/4.jpg);
}

.item:nth-child(5) .item__img-wrap {
  --aspect-ratio: 6/8;
  --image: url(https://tympanus.net/Tutorials/SmoothScrollAnimations/img/5.jpg);
}

*/

.item__img {
  --overflow: 40px;
  height: calc(100% + (2 * var(--overflow)));
  top: calc(-1 * var(--overflow));
  width: 100%;
  position: absolute;
  background-image: var(--image);
  background-size: cover;
  background-position: 50% 0%;
  will-change: transform;
}

.item__img--t1 {
  --overflow: 60px;
}

.item__img--t2 {
  --overflow: 80px;
}

.item__img--t3 {
  --overflow: 120px;
}

.item__caption {
  padding: 2rem 1rem;
}

.item__caption-title {
  font-family: "RalewayBlack";
  /* font-family: paralucent, sans-serif; */
  font-weight: 400;
  font-size: 3rem;
  margin: 0;
}

.item__tight {
  color: #888;
  font-size: 0.9em;
}

/* feature desc */
.item__caption-copy {
  margin: 0 0 40px 0;
  color: #999;
  /* max-width: 550px; */
  line-height: 2;
  font-size: 1.5em;
}

.item__caption-copy-etc {
  margin: 0 0 40px 0;
  color: #999;
  max-width: 550px;
  line-height: 1.7;
}

.item__caption-copy::before {
  content: "__";
  line-height: 1;
  color: var(--color-link);
  font-weight: 700;
  font-size: 3rem;
  margin: 0 0 1rem;
  display: block;
}

.test {
  border: solid 1px red;
}

.test2 {
  border: solid 1px green;
}

.test3 {
  border: solid 1px blue;
}

.test4 {
  border: solid 1px pink;
}

/*******************/
/**   Flex   **/
/******************/

.flex {
  display: flex;
}

.flexColumn {
  flex-direction: column;
}

.flexRow {
  flex-direction: row;
}

.flexSpaceBetween {
  justify-content: space-between;
}

/*******************/
/**   Utilities   **/
/******************/

.show {
  display: block;
}

.hide {
  display: none;
}

.errorText {
  color: #d8000c;
}

.centerChildren {
  justify-content: center;
  align-items: center;
}

.centerH {
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

.marginBot30 {
  margin-bottom: 30px;
}

.listBoxHolder {
  display: flex;
  margin: 20px 40px 20px 40px;
  justify-content: space-between;
  /* space-around; */
  flex-direction: "row";
  align-items: "center";
  width: 900px;
}

.listBox {
  position: relative;
  border: solid 1px #fff;
  /* flex-grow: 1; */
  width: 32%;
  min-height: 300px;
  background-color: #fff;
}

.listBoxInfo {
  padding: 15px;
}

.listItem {
  margin: 0 0 20px 0;
  overflow: hidden;
}

.listTitle {
  font-family: "RobotoMedium";
  font-size: 1.2em;
  margin: 0 0 5px 0;
}

.listText {
  font-family: "RobotoLight";
  font-size: 1.2em;
  line-height: 1.2em;
  margin: 0 0 5px 0;
  overflow: hidden;
}

.containerType1 {
  position: relative;
  border-top: solid 6px #01949e;
  border-bottom: solid 6px #01949e;
}

.containerType2 {
  position: relative;
  border-top: solid 6px #01949e;
}

/*********************/
/**** Page Parts ****/

#screen {
  width: 100%;
}

#header {
  height: 150px;
  width: 100%;
  padding: 20px 0 0 0;
  margin: 0 auto 60px auto;
}

/*   
  background: url(./img/Logo.gif) no-repeat; 
 height: 129px;
  width: 500px;
  */

.logo {
  background: url(./img/Logo.gif) no-repeat;
  height: 74px;
  width: 80px;
}

.profilePic {
  background: url(./img/Mike1.jpg) no-repeat;
  background-size: 140px 140px;
  /* background-attachment: fixed; */
  background-position: center;
  min-height: 140px;
  min-width: 140px;
  max-height: 140px;
  max-width: 140px;
  height: 140px;
  width: 140px;
  border-radius: 50%;

  border: solid 10px #6abdc5;
}

.section {
  overflow: hidden;
  max-width: 980px;
  width: 980px;
  padding: 0 0 30px 0;
}

#portfolio {
  overflow: hidden;
}

#footer {
  height: 45px;
  width: 100%;
  background-color: #fafafa;
  margin: 40px 0 0 0;
}

#copyright {
  font-size: 0.8em;
  text-align: center;
  padding: 15px 0 0 0;
}

.aboutCol {
  color: #999;
  line-height: 1.7;
  padding: 20px;
}

/**********************************/
/***            839             ***/
/**********************************/
@media all and (max-width: 839px) {
  #screen {
    width: 100%;
  }

  #header {
    height: 150px;
    width: 100%;
    padding: 20px 0 0 0;
    margin: 0 auto 60px auto;
  }

  .listBoxHolder {
    flex-direction: column;
    text-align: center;
  }

  .listBox {
    width: 100%;
    margin: 0;
    padding: 0;
    min-height: auto;
  }

  .listBoxInfo {
    padding: 0px;
  }

  .listTextBox {
    display: none;
  }

  #portfolio {
    max-width: 800px;
  }

  .section {
    max-width: 800px;
  }
}

/**********************************/
/***            500             ***/
/**********************************/
@media all and (max-width: 500px) {
  .item__img-wrap {
    max-width: 400px !important;
  }

  #portfolio {
    max-width: 400px;
  }

  .section {
    max-width: 400px;
  }
}


/* ----------- iPhone X ----------- */

/* Portrait and Landscape */
@media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-min-device-pixel-ratio: 3) {

  /* feature desc */
  .item__caption-copy {
    margin: 0 0 40px 0;
    color: #999;
    max-width: 550px;
    line-height: 1.7;
    font-size: 1.1em;
  }

  h2 {
    max-width: 350px;
  }

  .pTopTitle {
    max-width: 350px;
    line-height: 1.7px;
    color: #999;
  }

  .industry {
    max-width: 350px;
  }

  .aboutCol {
    max-width: 350px;
    color: #999;
    line-height: 1.7;
    padding: 20px;
  }

  .aboutCol2 {
    display: none;
  }
}

/* Portrait */
@media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-min-device-pixel-ratio: 3) and (orientation: portrait) {}

/* Landscape */
@media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-min-device-pixel-ratio: 3) and (orientation: landscape) {}